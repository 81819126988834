import { render, staticRenderFns } from "./PsoDashboard.vue?vue&type=template&id=0a18839d&scoped=true&"
import script from "./PsoDashboard.vue?vue&type=script&lang=js&"
export * from "./PsoDashboard.vue?vue&type=script&lang=js&"
import style0 from "./PsoDashboard.vue?vue&type=style&index=0&id=0a18839d&lang=scss&scoped=true&"
import style1 from "./PsoDashboard.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a18839d",
  null
  
)

export default component.exports