<template>
  <section class="container-fluid mt-4 pso-dashboard">
    <div class="">
      <h4 class="font-weight-bold text-left d-flex">
        <span class="mr-3">สรุปโดยรวมทุกจังหวัด</span>
        <multiselect
          @select="selectNationalPlanYear"
          :value="currentNationalPlan"
          deselect-label=""
          select-label=""
          track-by="year"
          label="year"
          :options="nationalPlanList"
          :searchable="false"
          :allow-empty="false"
          class="year-selection"
        >
          <template slot="singleLabel" slot-scope="{ option }"
          >แผนพลังงานปี {{ option.year | thai_year }}
          </template>
          <template slot="option" slot-scope="props">
            <div>ปี {{ props.option.year | thai_year }}</div>
          </template>
        </multiselect>
      </h4>
      <div class="d-flex mt-3 pb-2 border-bottom menu">
        <router-link
          :to="{ name: 'pso.overview' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.overview' }"
        >
          ภาพรวม
        </router-link>
        <router-link
          :to="{ name: 'pso.province_summary' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.province_summary' }"
        >
          สรุปข้อมูลพลังงานจังหวัด
        </router-link>
        <router-link
          :to="{ name: 'pso.evaluation' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.evaluation' }"
        >
          ประเมินแผน
        </router-link>
        <router-link
          :to="{ name: 'pso.national_plan' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.national_plan' }"
        >
          ความเชื่อมโยงแผน
        </router-link>
        <router-link
          :to="{ name: 'pso.announcement' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.announcement' }"
        >
          แก้ไขประกาศ
        </router-link>
        <router-link
          :to="{ name: 'pso.target_guideline' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'pso.target_guideline' }"
        >
          แก้ไขคำแนะนำเป้าประสงค์
        </router-link>
      </div>
    </div>
    <transition mode="out-in" name="fade">
      <router-view class="animated" />
    </transition>
  </section>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: 'PsoDashboard',

  components: {
    Multiselect,
  },

  data() {
    return {
      currentTab: this.$route.name,
    };
  },

  computed: {
    ...mapGetters(['nationalPlanList', 'currentNationalPlan']),
  },
  watch: {
    $route(to, from) {
      this.currentTab = to.name;
    },
  },
  methods: {
    ...mapMutations(['setCurrentNationalPlan']),
    selectProvince(province) {
    },
    selectNationalPlanYear(option, id) {
      this.setCurrentNationalPlan(option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.pso-dashboard {
  min-height: 100%;
  height: 1px;

  .menu {
    overflow-x: auto;
    overflow-y: hidden;

    .item.active {
      color: $red-color !important;
    }
  }
}

.year-selection {
  width: 200px;
}
</style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da;
}

.multiselect, .multiselect__single {
  font-size: 22px;
  padding-top: 2px;
}

.multiselect__input {
  border-color: #FFAE01;
}

.multiselect__select {
  margin-top: 5px;
}

.multiselect__option--highlight {
  background-color: #e74124;
}

.multiselect__option--selected.multiselect__option--highlight {
  background-color: #e74124;
}

</style>
